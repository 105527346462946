import React, { useEffect } from 'react';

const CheckComponent = ({ svgContent, checkId }) => {
    useEffect(() => {
      const elementId = `check-div-${checkId}`;
      const updateInnerHTML = () => {
        const element = document.getElementById(elementId);
        if (element) {
          element.innerHTML = svgContent;
        }
      };
      updateInnerHTML();
    }, [svgContent, checkId]);
  
    return <div id={`check-div-${checkId}`} data-v-8d721357="" data-v-a514b4df="" className="static snipcss0-5-306-328">
    </div>;
  };

  export default CheckComponent;