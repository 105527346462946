import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Developer from './components/Developer/Developer'
import Default from './components/Default/Default'
import { jwtDecode } from 'jwt-decode';
import dotenv from 'dotenv';
import { isMobileDevice } from './deviceUtils';

dotenv.config({path: '../../.env'});


function isTokenExpired(token) {
  try {
    const decoded = jwtDecode(token);
    const current = Date.now() / 1000; // UNIX timestamp in seconds
    return decoded.exp < current;
  } catch (e) {
    console.error('Error checking token expiration:', e);
    return false; // If an error occurs, handle it according to your needs
  }
}

const App = () => {

  if (isMobileDevice()) {
    return <div style={{
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh',
      margin: 20
    }}><p style={{textAlign: 'center'}}>Master Cooker is not optimized for mobile devices. Please try on desktop.</p></div>;
  }
  

  const REACT_APP_BACKEND = (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') ? process.env.REACT_APP_BACKEND_LOCAL : process.env.REACT_APP_BACKEND_PUBLIC;

  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
  if (isMaintenanceMode) {
    return <div>We are currently undergoing maintenance. Please check back later.</div>;
  }

  const [accessToken, setAccessToken] = useState('');
  const [accessTokenAPI, setAccessTokenAPI] = useState('');
  const [ethAddress, setEthAddress] = useState('');
  const [ethAddressAPI, setEthAddressAPI] = useState('');
  const [creditBalance, setCreditBalance] = useState('');
  const [creditBalanceAPI, setCreditBalanceAPI] = useState('');
  const [loginAction, setLoginAction] = useState(0);

  useEffect(() => {
    // Retrieve user and token from local storage
    const savedToken = localStorage.getItem('accessToken');
    if (savedToken && !isTokenExpired(savedToken)) {
      setAccessToken(savedToken);
      axios.defaults.headers.common['Authorization'] = `${savedToken}`;
    }  

    const savedTokenAPI = localStorage.getItem('accessTokenAPI');
    if (savedTokenAPI && !isTokenExpired(savedTokenAPI)) {
      setAccessTokenAPI(savedTokenAPI);
    }  

    // If access token is expired, then use refreshToken to get new access token
    if (savedToken && isTokenExpired(savedToken)) {
      try {
      console.log('access token is expired')
      const refreshTokenFunc = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        //console.log(refreshToken)
        if (refreshToken && !isTokenExpired(refreshToken)) {
          console.log('refreshToken is not expired so calling /token')
          const res = await axios.post(`${REACT_APP_BACKEND}/token`, { refreshToken });
        if (res.status === 200) {
          setAccessToken(res.data.accessToken); // Update access token state
          localStorage.setItem('accessToken', res.data.accessToken); // Update local storage
          axios.defaults.headers.common['Authorization'] = `${res.data.accessToken}`;
        }          
      }
      else {
        console.log('refresh token is also expired or no refresh token.')
        handleLogout();
      }
    }
    refreshTokenFunc();
  } catch(e) {
    console.log('error fetching token ', e)
  }
    }
  
    // Axios response interceptor
    const axiosInterceptor = axios.interceptors.response.use(response => response, async error => {
      const originalRequest = error.config;
      if (error.response.status === 403 && !originalRequest._retry) {
        try {
        console.log('access token expired. trying to renew')
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken && !isTokenExpired(refreshToken)) {
          console.log('refreshToken is not expired so calling /token')
          const res = await axios.post(`${REACT_APP_BACKEND}/token`, { refreshToken });
          if (res.status === 200) {
            console.log('renewing worked')
            setAccessToken(res.data.accessToken); // Update access token state
            localStorage.setItem('accessToken', res.data.accessToken); // Update local storage
            originalRequest.headers['Authorization'] = `${res.data.accessToken}`;
            console.log('re-sending original request with renewed access token')
            return axios(originalRequest); // Retry the original request
          }
        }
        else {
          console.log('refresh token is also expired or no refresh token.')
          handleLogout();
        }
      } catch(e) {
        console.log('error trying to renew token ', e)
      }
      }
      return Promise.reject(error);
    });

    const savedEthAddress = localStorage.getItem('ethAddress');
    if (savedEthAddress) {
      setEthAddress(savedEthAddress);
    }  

    const savedEthAddressAPI = localStorage.getItem('ethAddressAPI');
    if (savedEthAddressAPI) {
      setEthAddressAPI(savedEthAddressAPI);
    }  
  
    return () => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, []);
  
  const handleLogin = (userData) => {
    setAccessToken(userData.accessToken); // Update access token state
    localStorage.setItem('refreshToken', userData.refreshToken);
    localStorage.setItem('accessToken', userData.accessToken); // Update local storage
    axios.defaults.headers.common['Authorization'] = `${accessToken}`;

    // Decode the JWT to get the Ethereum address
    const decodedToken = jwtDecode(userData.accessToken);
    setEthAddress(decodedToken.ethAddress); 
    localStorage.setItem('ethAddress', decodedToken.ethAddress);

    setLoginAction(prev => prev + 1); // Increment to indicate a login action
  };
  
  const handleLoginAPI = (userData) => {
    setAccessTokenAPI(userData.accessToken); // Update access token state
    localStorage.setItem('accessTokenAPI', userData.accessToken); // Update local storage

    // Decode the JWT to get the Ethereum address
    const decodedToken = jwtDecode(userData.accessToken);
    setEthAddressAPI(decodedToken.ethAddress); 
    localStorage.setItem('ethAddress', decodedToken.ethAddress);
  };

  const handleLogout = () => {
    setAccessToken(''); // Clear access token state
    setEthAddress('');
    localStorage.removeItem('accessToken'); // Clear access token from local storage
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('ethAddress');
    axios.defaults.headers.common['Authorization'] = '';
  };

  const handleLogoutAPI = () => {
    setAccessTokenAPI(''); // Clear access token state
    setEthAddressAPI('');
    localStorage.removeItem('accessTokenAPI'); // Clear access token from local storage
    localStorage.removeItem('ethAddressAPI');
  };

  const updateCreditBalance = async () => {
    try {
          if (ethAddress) {
            const cb = await axios.get(`${REACT_APP_BACKEND}/user/credits/${ethAddress}`);
            setCreditBalance(cb.data.creditsLeft);
          }
      } catch (error) {
        console.error('Error fetching credit balance:', error);
      }
  }

  const updateCreditBalanceAPI = async () => {
    try {
          if (ethAddress) {
            const cb = await axios.get(`${REACT_APP_BACKEND}/user/apicredits/${ethAddress}`);
            setCreditBalanceAPI(cb.data.creditsLeft);
          }
      } catch (error) {
        console.error('Error fetching credit balance:', error);
      }
  }  
  

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/developer" element={<Developer ethAddress={ethAddressAPI} onLogin={handleLoginAPI} onLogout={handleLogoutAPI} creditBalance={creditBalanceAPI} updateCreditBalance={updateCreditBalanceAPI} contractAddress={"0xeF6565F5c395ff14BFF560fCDC85F23926DcE649"} accessToken={accessTokenAPI}/>} />

          <Route path="/" element={<Default ethAddress={ethAddress} handleLogin={handleLogin} handleLogout={handleLogout} creditBalance={creditBalance} updateCreditBalance={updateCreditBalance} accessToken={accessToken} loginAction={loginAction} contractAddress={"0xe2C964f2152b36b32aE5c05c0dFF5Be694a5b411"} />} />
        </Routes>
      </div>
    </Router>


  );
};

export default App;
