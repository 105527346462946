import React, { useState } from 'react';
import { ethers } from 'ethers';


const BuyCredits = ({ contractAddress, abi, updateCreditBalance }) => {
  const [credits, setCredits] = useState(0);
  const [ethAmount, setEthAmount] = useState(0);

 
   // Set up a provider
   const provider = new ethers.providers.Web3Provider(window.ethereum);
  
   // Create a new contract instance
   const contract = new ethers.Contract(contractAddress, abi, provider.getSigner());
 
   updateCreditBalance();
   
   const handleCreditChange = async (e) => {
     const creditValue = e.target.value;
     setCredits(creditValue);
     if (creditValue != '') {
         const rate = await contract.creditRate();
         const ethCost = ethers.utils.formatEther(rate.mul(creditValue));
         setEthAmount(ethCost);
     }
     else {
         setEthAmount(0);
         setCredits(0);
     }
   };

  const buyCredits = async () => {
    if (ethAmount == '') {
        console.log('You need to buy at least 1 cook');
        return;
    }
    try {
        // Get signer
        const signer = provider.getSigner();
        // Get the first account
        const account = await signer.getAddress();
        // Convert ETH amount to Wei
        const ethToSend = ethers.utils.parseEther(ethAmount);
        contract.buyCredits(account.toString(), ethToSend, credits, { value: ethToSend })
        .then(result => {
            console.log('Cooks purchased!', result);
            setCredits(0)
            setEthAmount(0)
            setTimeout(updateCreditBalance, 13000);
        })
        .catch(error => {
            if (error.code === 4001) {
            // Handle the case where the user has denied the transaction
            console.log("User denied transaction signature.");
            } else {
            // Handle other types of errors
            console.error('Error purchasing cooks:', JSON.stringify(error, null, 2));
            }
      });
    }
    catch(e){
        console.log('error ', e);
    }
  };

  return (
    <>
      <input data-v-b857464c="" data-v-2e84e883="" type="number" className='button snipcss0-4-4-5 style-sj4rx' id="style-evf6X" name="check1" onChange={handleCreditChange} placeholder="Cooks to Buy" value={credits ? credits : ''}/>
      <button data-v-0b6ae872="" data-v-10cea003="" className="button" onClick={buyCredits}>{`Buy ${credits} ${credits == 1 ? 'cook' : 'cooks'} for ${ethAmount ? ethAmount.substring(0,7) : ethAmount}Ξ`}
</button>
    </>
  );
};

export default BuyCredits;
