import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ethers } from 'ethers';
import './ChecksComponent.css';
import ChecksABI from '../../contracts/abi/ChecksABI.js';
import CheckComponent from './CheckComponent/CheckComponent.js'

const ChecksComponent = ({ accessToken, updateCreditBalance, loginAction, ethAddress }) => {

  const REACT_APP_BACKEND = (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') ? process.env.REACT_APP_BACKEND_LOCAL : process.env.REACT_APP_BACKEND_PUBLIC;


  const [checks, setChecks] = useState({ check1: '', check2: '', check3: '', check4: '' });
  const [svgResponse, setSvgResponse] = useState('');
  const [dnaResponse, setDnaResponse] = useState('');
  const [deetsResponse, setDeetsResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [svgResponseCheckSVGs, setSvgResponseCheckSVGs] = useState({ check1: '', check2: '', check3: '', check4: '' });
  const [dnaResponseCheckSVGs, setDnaResponseCheckSVGs] = useState({ check1: '', check2: '', check3: '', check4: '' });
  const [deetsResponseCheckSVGs, setDeetsResponseCheckSVGs] = useState({ check1: '', check2: '', check3: '', check4: '' });
  const timersRef = useRef({});
  const svgQueue = useRef([]);
  const dnaQueue = useRef([]);
  const deetsQueue = useRef([]);
  const [publicCook, setPublicCook] = useState(false);
  const [isDna1Hovered, setIsDna1Hovered] = useState(false);
  const [isDeets1Hovered, setIsDeets1Hovered] = useState(false);
  const [isDna2Hovered, setIsDna2Hovered] = useState(false);
  const [isDeets2Hovered, setIsDeets2Hovered] = useState(false);
  const [isDna3Hovered, setIsDna3Hovered] = useState(false);
  const [isDeets3Hovered, setIsDeets3Hovered] = useState(false);
  const [isDna4Hovered, setIsDna4Hovered] = useState(false);
  const [isDeets4Hovered, setIsDeets4Hovered] = useState(false);
  const [isDna5Hovered, setIsDna5Hovered] = useState(false);
  const [isDeets5Hovered, setIsDeets5Hovered] = useState(false);
  const [checkPrice, setCheckPrice] = useState({check1: {forSale: 'NO', price: 0, owner: ''}, check2: {forSale: 'NO', price: 0, owner: ''}, check3: {forSale: 'NO', price: 0, owner: ''}, check4: {forSale: 'NO', price: 0, owner: ''}})
  const [numberOfChecks, setNumberOfChecks] = useState({check1: {type: '', checks: 0, checkId: 0}, check2: {type: '', checks: 0, checkId: 0}, check3: {type: '', checks: 0, checkId: 0}, check4: {type: '', checks: 0, checkId: 0}});
  const [isValidCombo, setIsValidCombo] = useState(false);

  const parseSVG = (svgString) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svgString, "image/svg+xml");
  
    // Remove <animate> elements
    xmlDoc.querySelectorAll('animate').forEach(el => el.remove());
  
    // Serialize back to string
    const serializer = new XMLSerializer();
    const svgWithoutAnimate = serializer.serializeToString(xmlDoc.documentElement);
  
    return svgWithoutAnimate;
  };
  

  const processQueue = () => {
    if (svgQueue.current.length === 0) {
      // No more SVGs to process
      return;
    }
  
    const nextSVG = svgQueue.current.shift(); // Get the next SVG
    setSvgResponseCheckSVGs(prev => ({ ...prev, [nextSVG.checkName]: nextSVG.content }));
  
    setTimeout(() => {
      processQueue(); // Process the next SVG after a delay
    }, 1000); // Adjust delay as needed
  };

  const addSvgToQueue = (checkName, svgContent) => {
    svgQueue.current.push({ checkName, content: svgContent });
  
    if (svgQueue.current.length === 1) {
      // If this is the only item in the queue, start processing
      processQueue();
    }
  };

  const processDNAQueue = () => {
    if (dnaQueue.current.length === 0) {
      // No more SVGs to process
      return;
    }
  
    const nextDNA = dnaQueue.current.shift();
    setDnaResponseCheckSVGs(prev => ({ ...prev, [nextDNA.checkName]: nextDNA.content }));
  
    setTimeout(() => {
      processDNAQueue(); // Process the next DNA after a delay
    }, 1000); // Adjust delay as needed
  };

  const addDnaToQueue = (checkName, dnaContent) => {
    dnaQueue.current.push({ checkName, content: dnaContent });
  
    if (dnaQueue.current.length === 1) {
      // If this is the only item in the queue, start processing
      processDNAQueue();
    }
  };

  const processDeetsQueue = () => {
    if (deetsQueue.current.length === 0) {
      // No more SVGs to process
      return;
    }
  
    const nextDeets = deetsQueue.current.shift(); 
    setDeetsResponseCheckSVGs(prev => ({ ...prev, [nextDeets.checkName]: nextDeets.content }));
  
    setTimeout(() => {
      processDeetsQueue(); // Process the next DNA after a delay
    }, 1000); // Adjust delay as needed
  };

  const addDeetsToQueue = (checkName, deetsContent) => {
    deetsQueue.current.push({ checkName, content: deetsContent });
  
    if (deetsQueue.current.length === 1) {
      // If this is the only item in the queue, start processing
      processDeetsQueue();
    }
  };

  let provider;
  let contract;
if (window.ethereum) {
     // Set up a provider
     provider = new ethers.providers.Web3Provider(window.ethereum);
  
     // Create a new contract instance
     contract = new ethers.Contract('0x036721e5A769Cc48B3189EFbb9ccE4471E8A48B1', ChecksABI, provider.getSigner());
}

  useEffect(() => {
    // This effect runs when the 'loginAction' changes
    if (loginAction > 0) {
      setSvgResponse('');
      setDnaResponse('');
      setDeetsResponse('');
      // Execute any logic that needs to happen after login
    }
  }, [loginAction]);

  async function checkIfLoggedIn() {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        if (accounts.length > 0) {
            // User is logged in to MetaMask            
            return true;
        } else {
            // MetaMask is installed, but user is not logged in
            window.ethereum.request({ method: 'eth_requestAccounts' })
            return false;
        }
    } catch (error) {
        console.error('Error checking login status:', error);
        return false;
    }
}

  const handleCheckSubmit = async (e) => {
    if (!window.ethereum) {
      alert('Please install a Web3 wallet to be able to cook.');
      return;
    }
    checkIfLoggedIn();
    e.preventDefault();
    setIsLoading(true);
    try {
      let response;
      if (publicCook) {
        response = await axios.post(`${REACT_APP_BACKEND}/getCheckPublic`, checks, {
          headers: { Authorization: `${accessToken}` }
        });        
      }
      else {
      response = await axios.post(`${REACT_APP_BACKEND}/getCheck`, checks, {
        headers: { Authorization: `${accessToken}` }
      });
    }
      setSvgResponse(response.data.response);
      const responseDNA = response.data.responseDNA;
      const lines = responseDNA.split(',');
      lines.shift(); // Removes the first element of the array
      const newResult = lines.join('\n');
      
      const decodedResult = Buffer.from(newResult, 'base64').toString('utf-8');
      const attributes = JSON.parse(decodedResult).attributes;
      const dnaAside = generateAsideDNAFromDNA(attributes);
      setDnaResponse(dnaAside);
      updateCreditBalance();
    } catch (error) {
        const errorMessage = 
        error.response?.status == 500 ? 'There was a server error combining the Checks. Possibly due to inputs that can not be composited.' :
        ((error.response?.status == 402) ? 'You ran out of preview cooks.' :
        ((error.response?.status == 403) ? 'Your access token expired. Please log in again.' :
        ((error.response?.status == 429) ? error.response?.data?.message :
        'There was an error combining the Checks.')));
        setSvgResponse('<p style="margin-left:10; text-align:center">' + errorMessage + '</p>');
        setDnaResponse('');
        setDeetsResponse('');
    } finally {
      setIsLoading(false);
    }
  };


  const mixTokens = () => {
    // Extract values and shuffle them
    const values = Object.values(checks);
    for (let i = values.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [values[i], values[j]] = [values[j], values[i]]; // ES6 destructuring swap
    }

    // Assign the shuffled values back to the keys
    const shuffledChecks = Object.keys(checks).reduce((acc, key, index) => {
      acc[key] = values[index];
      return acc;
    }, {});

    setChecks(shuffledChecks);    
  }

  const handleChange = async (e) => {
    if (!window.ethereum) {
      alert('Please install a Web3 wallet to be able to cook.');
      return;
    }
    checkIfLoggedIn();

    setSvgResponse('');
    setDnaResponse('');
    setDeetsResponse('');
    
    //console.log('inside handle change');
    const value = parseInt(e.target.value, 10); // Convert input value to an integer
    const checkName = e.target.name; // Get the name of the check being changed
  
    // Clear the existing timer for this specific check
    if (timersRef.current[checkName]) {
      clearTimeout(timersRef.current[checkName]);
    }

    if (!isNaN(value)) { // Check if the conversion is successful
      setChecks({ ...checks, [checkName]: value });
    } else {
      setChecks({ ...checks, [checkName]: '' });
    }
  };
  
  // A separate useEffect for each check
  useEffect(() => {
    //console.log('change in check1')
    // Clear existing timer for check1
    clearTimeout(timersRef.current['check1']);
    if (checks.check1 > 0) {
      // Set a new timer for check1
      timersRef.current['check1'] = setTimeout(() => {
        if (checks.check1 != '') {
          fetchSVG('check1', checks.check1);
          fetchMetadata('check1', checks.check1);
          fetchDeets('check1', checks.check1);
        }
      }, 1000);
      setIsValidCombo(false);
      addSvgToQueue('check1', '<p style="text-align: center">Checking...</p>');
      addDnaToQueue('check1', '');
      addDeetsToQueue('check1', '');
    }
    else {
      setSvgResponseCheckSVGs({ ...svgResponseCheckSVGs, ['check1']: '' }); 
      setDnaResponseCheckSVGs({ ...dnaResponseCheckSVGs, ['check1']: '' }); 
      setDeetsResponseCheckSVGs({ ...deetsResponseCheckSVGs, ['check1']: '' }); 
    }

    // Cleanup function
    return () => clearTimeout(timersRef.current['check1']);
  }, [checks.check1]);

  useEffect(() => {
    // Clear existing timer for check2
    clearTimeout(timersRef.current['check2']);
    if (checks.check2 > 0) {
      // Set a new timer for check2
      timersRef.current['check2'] = setTimeout(() => {
        if (checks.check2 != '') {
          fetchSVG('check2', checks.check2);
          fetchMetadata('check2', checks.check2);
          fetchDeets('check2', checks.check2);
        }
      }, 1000);
      setIsValidCombo(false);
      addSvgToQueue('check2', '<p style="text-align: center">Checking...</p>');
      addDnaToQueue('check2', '');
      addDeetsToQueue('check2', '');
    }
    else {
      setSvgResponseCheckSVGs({ ...svgResponseCheckSVGs, ['check2']: '' });
      setDnaResponseCheckSVGs({ ...dnaResponseCheckSVGs, ['check2']: '' });
      setDeetsResponseCheckSVGs({ ...deetsResponseCheckSVGs, ['check2']: '' }); 
    }

    // Cleanup function
    return () => clearTimeout(timersRef.current['check2']);
  }, [checks.check2]);

  useEffect(() => {
    // Clear existing timer for check3
    clearTimeout(timersRef.current['check3']);    
    if (checks.check3 > 0) {
      // Set a new timer for check2
      timersRef.current['check3'] = setTimeout(() => {
        if (checks.check3 != '') {
          fetchSVG('check3', checks.check3);
          fetchMetadata('check3', checks.check3);
          fetchDeets('check3', checks.check3);
        }
      }, 1000);
      setIsValidCombo(false);
      addSvgToQueue('check3', '<p style="text-align: center">Checking...</p>');
      addDnaToQueue('check3', '');
      addDeetsToQueue('check3', '');
    }
    else {
      setSvgResponseCheckSVGs({ ...svgResponseCheckSVGs, ['check3']: '' });
      setDnaResponseCheckSVGs({ ...dnaResponseCheckSVGs, ['check3']: '' });
      setDeetsResponseCheckSVGs({ ...deetsResponseCheckSVGs, ['check3']: '' }); 
    }

    // Cleanup function
    return () => clearTimeout(timersRef.current['check3']);
  }, [checks.check3]);

  useEffect(() => {
    // Clear existing timer for check4
    clearTimeout(timersRef.current['check4']);
    if (checks.check4 > 0) {
      // Set a new timer for check2
      timersRef.current['check4'] = setTimeout(() => {
        if (checks.check4 != '') {
          fetchSVG('check4', checks.check4);
          fetchMetadata('check4', checks.check4);
          fetchDeets('check4', checks.check4);
        }
      }, 1000);
      setIsValidCombo(false);
      addSvgToQueue('check4', '<p style="text-align: center">Checking...</p>');
      addDnaToQueue('check4', '');
      addDeetsToQueue('check4', '');
    }
    else {
      setSvgResponseCheckSVGs({ ...svgResponseCheckSVGs, ['check4']: '' });
      setDnaResponseCheckSVGs({ ...dnaResponseCheckSVGs, ['check4']: '' });
      setDeetsResponseCheckSVGs({ ...deetsResponseCheckSVGs, ['check4']: '' }); 
    }

    // Cleanup function
    return () => clearTimeout(timersRef.current['check4']);
  }, [checks.check4]);
  

  const fetchSVG = (checkName, checkId) => {
    //console.log('fetching SVG for ', checkName, checkId)
    contract.svg(checkId)
    .then(result => {
        //console.log('got SVG')
        
        const svgWithoutAnimate = parseSVG(result);
        addSvgToQueue(checkName, svgWithoutAnimate);
        
    })
    .catch(error => {
        addSvgToQueue(checkName, '');
        console.error(`Error obtaining SVG for check id ${checkId}:`, error);
  })
  };

  const generateAsideFromDNA = (attributes) => {
    
    const attributeHtml = attributes.map(attr => {
        return `<div data-v-021bb64c="" data-v-a514b4df="" class="attribute">
                    <span data-v-021bb64c="">${attr.trait_type}</span><span data-v-021bb64c="">${attr.value}</span>
                </div>`;
    }).join('\n');

    return {
            __html: `<div data-v-a514b4df="">DNA</div>
                ${attributeHtml}
            `
    }
}

const generateAsideDNAFromDNA = (attributes) => {
    
  const attributeHtml = attributes.map(attr => {
      return `<div data-v-021bb64c="" data-v-a514b4df="" class="attribute">
                  <span data-v-021bb64c="">${attr.trait_type}</span><span data-v-021bb64c="">${attr.value}</span>
              </div>`;
  }).join('\n');

  return `<div data-v-a514b4df="">DNA</div>
              ${attributeHtml}
          `;
}

const generateAsideFromDeets = (attributes) => {
    
  const attributeHtml = attributes.map(attr => {
      return `<div data-v-021bb64c="" data-v-a514b4de="" class="attribute">
                  <span data-v-021bb64c="">${attr.trait_type}</span><span data-v-021bb64c="">${attr.value}</span>
              </div>`;
  }).join('\n');

  return {
          __html: `<div data-v-a514b4df="">DETAILS</div>
              ${attributeHtml}
          `
  }
}

  const fetchMetadata = (checkName, checkId) => {
    
    contract.tokenURI(checkId)
    .then(result => {

        const lines = result.split(',');
        lines.shift(); // Removes the first element of the array
        const newResult = lines.join('\n');
        
        const decodedResult = Buffer.from(newResult, 'base64').toString('utf-8');
        const attributes = JSON.parse(decodedResult).attributes;
        const dnaAside = generateAsideFromDNA(attributes);
        addDnaToQueue(checkName, dnaAside);
        
        let dayValue, checksValue;

        attributes.forEach(trait => {
            if (trait.trait_type === "Day") {
                dayValue = trait.value;
            } else if (trait.trait_type === "Checks") {
                checksValue = trait.value;
            }
        });

        setNumberOfChecks(prevChecks => ({
          ...prevChecks, 
          [checkName]: {
              type: dayValue ? 'original' : '', 
              checks: checksValue ? checksValue : 0,
              checkId: checkId
          }
        }));
        
    })
    .catch(error => {
        console.error(`Error obtaining Metadata for check id ${checkId}:`, error);
        setDnaResponseCheckSVGs({ ...dnaResponseCheckSVGs, [checkName]: '' }); 
  })
  };


  const fetchDeets = (checkName, checkId) => {
    
    axios.get(`${REACT_APP_BACKEND}/check/${checkId}`)
    .then(async result => {
        const ownerObject = result.data;
        let ownerAddress = ownerObject.owner;
        if (ownerAddress == ethAddress) {
          ownerObject.owner = 'YOU';
          ownerAddress = 'YOU';
        }
        else {
          provider = new ethers.providers.Web3Provider(window.ethereum);
          const ens = await lookupEnsName(ownerObject.owner);
          const address = ens == '' ? formatEthereumAddress(ownerObject.owner) : ens;
          ownerObject.owner = address;
        }      
        const transformedArray = Object.keys(ownerObject).map(key => {
          return { 
              trait_type: key, 
              value: key == 'price' ? ownerObject[key].toString() + 'Ξ' : ownerObject[key] 
          };
        });

      setCheckPrice(prevCheckPrice => ({
         ...prevCheckPrice, 
         [checkName]: {
          forSale: ownerObject.forSale, 
          price: ownerObject.price, 
          owner: ownerAddress
        } 
      }));

      const deetsAside = generateAsideFromDeets(transformedArray);
      addDeetsToQueue(checkName, deetsAside);              

    })
    .catch(error => {
        console.error(`Error obtaining Deets for check id ${checkId}:`, error);
        setDeetsResponseCheckSVGs({ ...deetsResponseCheckSVGs, [checkName]: '' }); 
  })
  };

  useEffect(() => {
    updateResultDeets();
  },[checkPrice.check1, checkPrice.check2, checkPrice.check3, checkPrice.check4])  

  const updateResultDeets = () => {

    if (checkPrice.check1.owner == '' || checkPrice.check2.owner == '' || checkPrice.check3.owner == '' || checkPrice.check4.owner == '') {
      //setDeetsResponse('');
    }
    else if ((checkPrice.check1.owner == 'YOU' || checkPrice.check1.forSale == 'YES')
      && (checkPrice.check2.owner == 'YOU' || checkPrice.check2.forSale == 'YES')
      && (checkPrice.check3.owner == 'YOU' || checkPrice.check3.forSale == 'YES')
      && (checkPrice.check4.owner == 'YOU' || checkPrice.check4.forSale == 'YES')) {

      const price = ((checkPrice.check1.owner == 'YOU' ? 0 : checkPrice.check1.price) +
                    (checkPrice.check2.owner == 'YOU' ? 0 : checkPrice.check2.price) +
                    (checkPrice.check3.owner == 'YOU' ? 0 : checkPrice.check3.price) +
                    (checkPrice.check4.owner == 'YOU' ? 0 : checkPrice.check4.price)).toFixed(4);

      setDeetsResponse(`<div data-v-a514b4df="">DETAILS</div><div data-v-021bb64c="" data-v-a514b4de="" class="attribute">
        <span data-v-021bb64c="">COST TO CREATE</span><span data-v-021bb64c="">${price}Ξ</span>
        </div>`);         

      }
    else {
      setDeetsResponse(`<div data-v-a514b4df="">DETAILS</div><div data-v-021bb64c="" data-v-a514b4de="" class="attribute">
                  <span data-v-021bb64c="">COST TO CREATE</span><span data-v-021bb64c="">PRICELESS</span>
              </div>`);
    }
  }

  useEffect(() => {
    return () => {
      svgQueue.current = []; // Clear the queue
      // Clear any ongoing timeouts if you have set any
    };
  }, []);

  useEffect(() => {
    return () => {
      dnaQueue.current = []; // Clear the queue
      // Clear any ongoing timeouts if you have set any
    };
  }, []);

  useEffect(() => {
    return () => {
      deetsQueue.current = []; // Clear the queue
      // Clear any ongoing timeouts if you have set any
    };
  }, []);

  useEffect(() => {
    const updateInnerHTML = (elementId, htmlContent) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.innerHTML = htmlContent;
      }
    };
  
    updateInnerHTML('check1-div', svgResponseCheckSVGs.check1);
  }, [svgResponseCheckSVGs.check1]);
  

  useEffect(() => {
    const updateInnerHTML = (elementId, htmlContent) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.innerHTML = htmlContent;
      }
    };
  
    updateInnerHTML('check2-div', svgResponseCheckSVGs.check2);
  }, [svgResponseCheckSVGs.check2]);

  useEffect(() => {
    const updateInnerHTML = (elementId, htmlContent) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.innerHTML = htmlContent;
      }
    };
  
    updateInnerHTML('check3-div', svgResponseCheckSVGs.check3);
  }, [svgResponseCheckSVGs.check3]);

  useEffect(() => {
    const updateInnerHTML = (elementId, htmlContent) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.innerHTML = htmlContent;
      }
    };
  
    updateInnerHTML('check4-div', svgResponseCheckSVGs.check4);
  }, [svgResponseCheckSVGs.check4]);

  useEffect(() => {
    updateValidCombo();
  }, [numberOfChecks])

  const updateValidCombo = () => {
    setIsValidCombo(
      numberOfChecks.check1.type == 'original' &&
      numberOfChecks.check2.type == 'original' &&
      numberOfChecks.check3.type == 'original' &&
      numberOfChecks.check4.type == 'original' && 
      numberOfChecks.check1.checks == numberOfChecks.check2.checks &&
      numberOfChecks.check2.checks == numberOfChecks.check3.checks &&
      numberOfChecks.check3.checks == numberOfChecks.check4.checks &&
      numberOfChecks.check1.checkId != numberOfChecks.check2.checkId &&
      numberOfChecks.check1.checkId != numberOfChecks.check3.checkId &&
      numberOfChecks.check1.checkId != numberOfChecks.check4.checkId &&
      numberOfChecks.check2.checkId != numberOfChecks.check3.checkId &&
      numberOfChecks.check2.checkId != numberOfChecks.check4.checkId &&
      numberOfChecks.check3.checkId != numberOfChecks.check4.checkId
    )
  }

  function handlePrivate() {
    setPublicCook(false)
  }

  function handlePublic() {
    setPublicCook(true);
  }

  async function lookupEnsName(ethereumAddress) {
    try {
      // Connect to the Ethereum network
      const provider = new ethers.providers.Web3Provider(window.ethereum);
  
      // Perform a reverse ENS lookup
      const ensName = await provider.lookupAddress(ethereumAddress);
  
      if (ensName) {
        //console.log(`The ENS name for address ${ethereumAddress} is: ${ensName}`);
        return ensName;
      } else {
        //console.log(`No ENS name found for address ${ethereumAddress}`);
        return '';
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  function formatEthereumAddress(address) {
    if (address.length < 8) {
        throw new Error("Invalid Ethereum address");
    }
  
    const firstSix = address.slice(0, 6);
    const lastTwo = address.slice(-2);
    return `${firstSix}...${lastTwo}`;
  }

  
  return (
    
    <div data-v-aaa30f88="" className="inner snipcss-9lksE style-Bnr8f" id="style-Bnr8f" style={{marginTop: 30, width: '95%'}}>
    <div data-v-1e53e9ba="" className="toggle">
      <div className="spacer"/>
        <button data-v-b857464c="" data-v-1e53e9ba="" className={publicCook ? 'button sm rounded' : 'button active sm rounded'} style={{minWidth: 60, marginBottom:18, marginRight:10, fontSize:15}} onClick={handlePrivate}> 
          Private 
        </button>
        <button data-v-b857464c="" data-v-1e53e9ba="" className={publicCook ? 'button active sm rounded' : 'button sm rounded'} style={{minWidth: 60, marginBottom:18, fontSize:15}} onClick={handlePublic}> 
          Public 
        </button>
        <div className="spacer2"/>
    </div>
    <div data-v-aa7f54ab="" data-v-70500983="" className="page snipcss0-0-0-1" style={{marginBottom:150}}>
      <article data-v-d392d28c="" data-v-2e84e883="" className="snipcss0-1-1-2 style-fAK3b" id="style-fAK3b">
        <header data-v-2e84e883="" className="snipcss0-2-2-3">
          <div data-v-2e84e883="" className="snipcss0-3-3-4">
            <input data-v-b857464c="" data-v-2e84e883="" className='button snipcss0-4-4-5 style-sj4rx' id="style-evf6X" name="check1" value={checks.check1} onChange={handleChange} placeholder="Token ID" />
            
            <span data-v-2e84e883="" className="snipcss0-4-4-8">
              <svg data-v-a6425f4c="" data-v-2e84e883="" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" className="snipcss0-5-8-9">
                <path data-v-a6425f4c="" fillRule="evenodd" clipRule="evenodd" d="M42.7207 19.7715C41.3379 17.5098 38.8457 16 36 16C33.1543 16 30.6611 17.5098 29.2783 19.7734C26.7012 19.1523 23.8701 19.8477 21.8584 21.8594C19.8457 23.8711 19.1514 26.7012 19.7734 29.2793C17.5107 30.6621 16 33.1543 16 36C16 38.8457 17.5098 41.3379 19.7725 42.7207C19.1504 45.2988 19.8457 48.1309 21.8574 50.1426C23.8701 52.1543 26.7012 52.8477 29.2783 52.2266C30.6611 54.4902 33.1543 56 36 56C37.0635 56 38.0781 55.7891 39.0039 55.4062C40.5537 54.7656 41.8545 53.6445 42.7207 52.2285C45.2988 52.8516 48.1299 52.1543 50.1426 50.1426C52.1543 48.1289 52.8496 45.2988 52.2266 42.7207C54.4893 41.3379 56 38.8457 56 36C56 33.1543 54.4893 30.6621 52.2266 29.2793C52.8486 26.7012 52.1543 23.8691 50.1416 21.8574C48.1299 19.8457 45.2979 19.1484 42.7207 19.7715ZM34.811 43.197L43.9359 29.5111C45.0673 27.8188 42.4333 26.0612 41.3041 27.7551L33.2509 39.8408L30.5087 37.1044C29.0747 35.6606 26.8372 37.8964 28.2769 39.3362L32.6193 43.6349C33.3467 44.1237 34.3261 43.9227 34.811 43.197Z" fill="currentColor">
                </path>
              </svg>
            </span>
          </div>
          <div data-v-2e84e883="" className="snipcss0-3-3-4">
          <input data-v-b857464c="" data-v-2e84e883="" className='button snipcss0-4-4-5 style-sj4rx' id="style-evf6X" name="check2" value={checks.check2} onChange={handleChange} placeholder="Token ID" />
            <span data-v-2e84e883="" className="snipcss0-4-10-14">
              <svg data-v-96fbe41a="" data-v-2e84e883="" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" className="snipcss0-5-14-15">
                <path data-v-96fbe41a="" fillRule="evenodd" clipRule="evenodd" d="M42.7207 19.7715C41.3379 17.5098 38.8457 16 36 16C33.1543 16 30.6611 17.5098 29.2783 19.7734C26.7012 19.1523 23.8701 19.8477 21.8584 21.8594C19.8457 23.8711 19.1514 26.7012 19.7734 29.2793C17.5107 30.6621 16 33.1543 16 36C16 38.8457 17.5098 41.3379 19.7725 42.7207C19.1504 45.2988 19.8457 48.1309 21.8574 50.1426C23.8701 52.1543 26.7012 52.8477 29.2783 52.2266C30.6611 54.4902 33.1543 56 36 56C37.0635 56 38.0781 55.7891 39.0039 55.4062C40.5537 54.7656 41.8545 53.6445 42.7207 52.2285C45.2988 52.8516 48.1299 52.1543 50.1426 50.1426C52.1543 48.1289 52.8496 45.2988 52.2266 42.7207C54.4893 41.3379 56 38.8457 56 36C56 33.1543 54.4893 30.6621 52.2266 29.2793C52.8486 26.7012 52.1543 23.8691 50.1416 21.8574C48.1299 19.8457 45.2979 19.1484 42.7207 19.7715ZM41.9026 28.0961C42.5079 27.4908 43.4893 27.4908 44.0946 28.0961C44.6999 28.7014 44.6999 29.6828 44.0946 30.2881L38.2866 36.0961L43.651 41.4605C44.2563 42.0658 44.2563 43.0472 43.651 43.6525C43.0456 44.2578 42.0642 44.2578 41.4589 43.6525L36.0946 38.2882L30.7303 43.6524C30.125 44.2577 29.1436 44.2577 28.5383 43.6524C27.9329 43.0471 27.9329 42.0657 28.5383 41.4604L33.9025 36.0961L28.0946 30.2882C27.4893 29.6829 27.4893 28.7015 28.0946 28.0962C28.6999 27.4909 29.6813 27.4909 30.2866 28.0962L36.0946 33.9041L41.9026 28.0961Z" fill="currentColor">
                </path>
              </svg>
            </span>
          </div>
          <div data-v-2e84e883="" className="snipcss0-3-3-10">
          <input data-v-b857464c="" data-v-2e84e883="" className='button snipcss0-4-4-5 style-sj4rx' id="style-evf6X" name="check3" value={checks.check3} onChange={handleChange} placeholder="Token ID" />
            <span data-v-2e84e883="" className="snipcss0-4-10-14">
              <svg data-v-96fbe41a="" data-v-2e84e883="" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" className="snipcss0-5-14-15">
                <path data-v-96fbe41a="" fillRule="evenodd" clipRule="evenodd" d="M42.7207 19.7715C41.3379 17.5098 38.8457 16 36 16C33.1543 16 30.6611 17.5098 29.2783 19.7734C26.7012 19.1523 23.8701 19.8477 21.8584 21.8594C19.8457 23.8711 19.1514 26.7012 19.7734 29.2793C17.5107 30.6621 16 33.1543 16 36C16 38.8457 17.5098 41.3379 19.7725 42.7207C19.1504 45.2988 19.8457 48.1309 21.8574 50.1426C23.8701 52.1543 26.7012 52.8477 29.2783 52.2266C30.6611 54.4902 33.1543 56 36 56C37.0635 56 38.0781 55.7891 39.0039 55.4062C40.5537 54.7656 41.8545 53.6445 42.7207 52.2285C45.2988 52.8516 48.1299 52.1543 50.1426 50.1426C52.1543 48.1289 52.8496 45.2988 52.2266 42.7207C54.4893 41.3379 56 38.8457 56 36C56 33.1543 54.4893 30.6621 52.2266 29.2793C52.8486 26.7012 52.1543 23.8691 50.1416 21.8574C48.1299 19.8457 45.2979 19.1484 42.7207 19.7715ZM41.9026 28.0961C42.5079 27.4908 43.4893 27.4908 44.0946 28.0961C44.6999 28.7014 44.6999 29.6828 44.0946 30.2881L38.2866 36.0961L43.651 41.4605C44.2563 42.0658 44.2563 43.0472 43.651 43.6525C43.0456 44.2578 42.0642 44.2578 41.4589 43.6525L36.0946 38.2882L30.7303 43.6524C30.125 44.2577 29.1436 44.2577 28.5383 43.6524C27.9329 43.0471 27.9329 42.0657 28.5383 41.4604L33.9025 36.0961L28.0946 30.2882C27.4893 29.6829 27.4893 28.7015 28.0946 28.0962C28.6999 27.4909 29.6813 27.4909 30.2866 28.0962L36.0946 33.9041L41.9026 28.0961Z" fill="currentColor">
                </path>
              </svg>
            </span>
          </div>    
          <div data-v-2e84e883="" className="snipcss0-3-3-10">
          <input data-v-b857464c="" data-v-2e84e883="" className='button snipcss0-4-4-5 style-sj4rx' id="style-evf6X" name="check4" value={checks.check4} onChange={handleChange} placeholder="Token ID" />
            <span data-v-2e84e883="" className="snipcss0-4-10-14">
              <svg data-v-96fbe41a="" data-v-2e84e883="" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" className="snipcss0-5-14-15">
                <path data-v-96fbe41a="" fillRule="evenodd" clipRule="evenodd" d="M42.7207 19.7715C41.3379 17.5098 38.8457 16 36 16C33.1543 16 30.6611 17.5098 29.2783 19.7734C26.7012 19.1523 23.8701 19.8477 21.8584 21.8594C19.8457 23.8711 19.1514 26.7012 19.7734 29.2793C17.5107 30.6621 16 33.1543 16 36C16 38.8457 17.5098 41.3379 19.7725 42.7207C19.1504 45.2988 19.8457 48.1309 21.8574 50.1426C23.8701 52.1543 26.7012 52.8477 29.2783 52.2266C30.6611 54.4902 33.1543 56 36 56C37.0635 56 38.0781 55.7891 39.0039 55.4062C40.5537 54.7656 41.8545 53.6445 42.7207 52.2285C45.2988 52.8516 48.1299 52.1543 50.1426 50.1426C52.1543 48.1289 52.8496 45.2988 52.2266 42.7207C54.4893 41.3379 56 38.8457 56 36C56 33.1543 54.4893 30.6621 52.2266 29.2793C52.8486 26.7012 52.1543 23.8691 50.1416 21.8574C48.1299 19.8457 45.2979 19.1484 42.7207 19.7715ZM41.9026 28.0961C42.5079 27.4908 43.4893 27.4908 44.0946 28.0961C44.6999 28.7014 44.6999 29.6828 44.0946 30.2881L38.2866 36.0961L43.651 41.4605C44.2563 42.0658 44.2563 43.0472 43.651 43.6525C43.0456 44.2578 42.0642 44.2578 41.4589 43.6525L36.0946 38.2882L30.7303 43.6524C30.125 44.2577 29.1436 44.2577 28.5383 43.6524C27.9329 43.0471 27.9329 42.0657 28.5383 41.4604L33.9025 36.0961L28.0946 30.2882C27.4893 29.6829 27.4893 28.7015 28.0946 28.0962C28.6999 27.4909 29.6813 27.4909 30.2866 28.0962L36.0946 33.9041L41.9026 28.0961Z" fill="currentColor">
                </path>
              </svg>
            </span>
          </div>                    
        </header>
        <main data-v-2e84e883="" className="snipcss0-2-2-16">
          <div data-v-2e84e883="" className="parent snipcss0-3-16-17">
              <article data-v-a514b4df data-v-2e84e883 className="simple">
                {dnaResponseCheckSVGs.check1 && <button data-v-b857464c="" data-v-a514b4df="" className={`button show-dna rounded sm ${isDeets1Hovered ? 'hidden' : ''}`} onMouseEnter={() => {
                  setIsDna1Hovered(true);
                }} onMouseLeave={() => {
                  setIsDna1Hovered(false)
                }}>DNA</button>}
                <aside data-v-a514b4df="" dangerouslySetInnerHTML={dnaResponseCheckSVGs.check1 ? dnaResponseCheckSVGs.check1 : {__html: ''}}>
                </aside>
                {deetsResponseCheckSVGs.check1 && <button data-v-b857464c="" data-v-a514b4df="" className={`button show-deets rounded sm ${isDna1Hovered ? 'hidden' : ''}`}  onMouseEnter={() => {
                  setIsDeets1Hovered(true);
                }} onMouseLeave={() => {
                  setIsDeets1Hovered(false)
                }}>Deets</button>}
                <aside data-v-a514b4df="" dangerouslySetInnerHTML={deetsResponseCheckSVGs.check1 ? deetsResponseCheckSVGs.check1 : {__html: ''}}>
                </aside>
                <CheckComponent svgContent={svgResponseCheckSVGs.check1} checkId={1}/>
            </article>

          </div>
          <div data-v-2e84e883="" className="parent snipcss0-3-16-17">
          <article data-v-a514b4df data-v-2e84e883 className="simple">
                {dnaResponseCheckSVGs.check2 && <button data-v-b857464c="" data-v-a514b4df="" className={`button show-dna rounded sm ${isDeets2Hovered ? 'hidden' : ''}`} onMouseEnter={() => {
                  setIsDna2Hovered(true);
                }} onMouseLeave={() => {
                  setIsDna2Hovered(false)
                }}>DNA</button>}
                <aside data-v-a514b4df="" dangerouslySetInnerHTML={dnaResponseCheckSVGs.check2 ? dnaResponseCheckSVGs.check2 : {__html: ''}}>
                </aside>
                {deetsResponseCheckSVGs.check2 && <button data-v-b857464c="" data-v-a514b4df="" className={`button show-deets rounded sm ${isDna2Hovered ? 'hidden' : ''}`}  onMouseEnter={() => {
                  setIsDeets2Hovered(true);
                }} onMouseLeave={() => {
                  setIsDeets2Hovered(false)
                }}>Deets</button>}
                <aside data-v-a514b4df="" dangerouslySetInnerHTML={deetsResponseCheckSVGs.check2 ? deetsResponseCheckSVGs.check2 : {__html: ''}}>
                </aside>
                <CheckComponent svgContent={svgResponseCheckSVGs.check2} checkId={2}/>
            </article>
            </div>
            <div data-v-2e84e883="" className="parent snipcss0-3-16-17">
            <article data-v-a514b4df data-v-2e84e883 className="simple">
                {dnaResponseCheckSVGs.check3 && <button data-v-b857464c="" data-v-a514b4df="" className={`button show-dna rounded sm ${isDeets3Hovered ? 'hidden' : ''}`} onMouseEnter={() => {
                  setIsDna3Hovered(true);
                }} onMouseLeave={() => {
                  setIsDna3Hovered(false)
                }}>DNA</button>}
                <aside data-v-a514b4df="" dangerouslySetInnerHTML={dnaResponseCheckSVGs.check3 ? dnaResponseCheckSVGs.check3 : {__html: ''}}>
                </aside>
                {deetsResponseCheckSVGs.check3 && <button data-v-b857464c="" data-v-a514b4df="" className={`button show-deets rounded sm ${isDna3Hovered ? 'hidden' : ''}`}  onMouseEnter={() => {
                  setIsDeets3Hovered(true);
                }} onMouseLeave={() => {
                  setIsDeets3Hovered(false)
                }}>Deets</button>}
                <aside data-v-a514b4df="" dangerouslySetInnerHTML={deetsResponseCheckSVGs.check3 ? deetsResponseCheckSVGs.check3 : {__html: ''}}>
                </aside>
                <CheckComponent svgContent={svgResponseCheckSVGs.check3} checkId={3}/>
            </article>
            </div>
            <div data-v-2e84e883="" className="parent snipcss0-3-16-17">
            <article data-v-a514b4df data-v-2e84e883 className="simple">
                {dnaResponseCheckSVGs.check4 && <button data-v-b857464c="" data-v-a514b4df="" className={`button show-dna rounded sm ${isDeets4Hovered ? 'hidden' : ''}`} onMouseEnter={() => {
                  setIsDna4Hovered(true);
                }} onMouseLeave={() => {
                  setIsDna4Hovered(false)
                }}>DNA</button>}
                <aside data-v-a514b4df="" dangerouslySetInnerHTML={dnaResponseCheckSVGs.check4 ? dnaResponseCheckSVGs.check4 : {__html: ''}}>
                </aside>
                {deetsResponseCheckSVGs.check4 && <button data-v-b857464c="" data-v-a514b4df="" className={`button show-deets rounded sm ${isDna4Hovered ? 'hidden' : ''}`}  onMouseEnter={() => {
                  setIsDeets4Hovered(true);
                }} onMouseLeave={() => {
                  setIsDeets4Hovered(false)
                }}>Deets</button>}
                <aside data-v-a514b4df="" dangerouslySetInnerHTML={deetsResponseCheckSVGs.check4 ? deetsResponseCheckSVGs.check4 : {__html: ''}}>
                </aside>
                <CheckComponent svgContent={svgResponseCheckSVGs.check4} checkId={4}/>
            </article>
            </div>
          <button data-v-b857464c="" data-v-2e84e883="" className="button swap sm rounded snipcss0-3-16-593" onClick={mixTokens}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-shuffle" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5"/>
              <path d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z"/>
            </svg>
          </button>
          <div data-v-2e84e883="" className="child snipcss0-3-16-595">
          {isLoading ? (
        <p style={{textAlign:"center", margin:10, fontSize:20}}>👨🏽‍🍳 ...Cooking... 👨🏽‍🍳</p> // You can replace this with a spinner or any loading component
      ) : (<article data-v-a514b4df data-v-2e84e883 className="simple">
      {dnaResponse && <button data-v-b857464c="" data-v-a514b4df="" className={`button show-dna rounded sm ${isDeets5Hovered ? 'hidden' : ''}`}  onMouseEnter={() => {
                  setIsDna5Hovered(true);
                }} onMouseLeave={() => {
                  setIsDna5Hovered(false)
                }}>DNA</button>}
      <aside data-v-a514b4df="" dangerouslySetInnerHTML={dnaResponse ? {__html: dnaResponse} : {__html: ''}}>
      </aside>  
      {deetsResponse && <button data-v-b857464c="" data-v-a514b4df="" className={`button show-deets rounded sm ${isDna5Hovered ? 'hidden' : ''}`}  onMouseEnter={() => {
                  setIsDeets5Hovered(true);
                }} onMouseLeave={() => {
                  setIsDeets5Hovered(false)
                }}>Deets</button>}
                <aside data-v-a514b4df="" dangerouslySetInnerHTML={deetsResponse ? {__html: deetsResponse} : {__html: ''}}>
                </aside>            
            <div data-v-8d721357="" data-v-2e84e883="" className="snipcss0-4-595-596" dangerouslySetInnerHTML={{ __html: svgResponse }}>  
            </div>
            </article>)}
          </div>
          <aside data-v-2e84e883="" className="parent-attributes snipcss0-3-16-740">
            <div data-v-2e84e883="" className="snipcss0-4-740-741">

            </div>
            <div data-v-021bb64c="" data-v-2e84e883="" className="attribute snipcss0-4-740-743">
            </div>
            <div data-v-021bb64c="" data-v-2e84e883="" className="attribute snipcss0-4-740-746">
            </div>
            <div data-v-021bb64c="" data-v-2e84e883="" className="attribute snipcss0-4-740-749">
              <span data-v-2e84e883="" className="snipcss0-5-749-751">
                <small data-v-2e84e883="" className="next-day snipcss0-6-751-752">
                  <div data-v-10441266="" data-v-2e84e883="" className="countdown snipcss0-7-752-753">
                  </div>
                </small>
              </span>
            </div>
          </aside>
          <aside data-v-2e84e883="" className="parent-attributes snipcss0-3-16-755">
            <div data-v-2e84e883="" className="snipcss0-4-755-756">
              
            </div>
            <div data-v-021bb64c="" data-v-2e84e883="" className="attribute snipcss0-4-755-758">
              <span data-v-021bb64c="" className="snipcss0-5-758-759">
                
              </span>
              <span data-v-021bb64c="" className="snipcss0-5-758-760">
                
              </span>
            </div>
            <div data-v-021bb64c="" data-v-2e84e883="" className="attribute snipcss0-4-755-761">
              <span data-v-021bb64c="" className="snipcss0-5-761-762">
                
              </span>
              <span data-v-021bb64c="" className="snipcss0-5-761-763">
                
              </span>
            </div>
            <div data-v-021bb64c="" data-v-2e84e883="" className="attribute snipcss0-4-755-764">
              <span data-v-021bb64c="" className="snipcss0-5-764-765">
                
              </span>
              <span data-v-021bb64c="" className="snipcss0-5-764-766">
                
              </span>
            </div>
          </aside>
          <button data-v-b857464c="" data-v-2e84e883="" className="button action light nowrap snipcss0-3-16-767 style-pXrCk" id="style-pXrCk" onClick={handleCheckSubmit} disabled={!isValidCombo}>
            <span data-v-2e84e883="" className="snipcss0-4-767-768">
              Preview #{checks.check1} ({publicCook? 'Public & Free' : 'Private & Costs 1 cook'})
            </span>
          </button>
        </main>
      </article>
    </div>
  </div>


  );
};

export default ChecksComponent;
