import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import axios from 'axios';
import BuyCredits from '../BuyCredits/BuyCredits';
import MasterCookerCreditsABI from '../../contracts/abi/MasterCookerCreditsABI.js';
import Logout from '../Logout/Logout.js';
import CreditBalance from '../CreditBalance/CreditBalance.js';
import dotenv from 'dotenv';

dotenv.config({path: '../../.env'});

const NavBar = ({ titleText, ethAddressIn, onLogin, onLogout, creditBalance, updateCreditBalance, contractAddress }) => {

  const REACT_APP_BACKEND = (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') ? process.env.REACT_APP_BACKEND_LOCAL : process.env.REACT_APP_BACKEND_PUBLIC;

  const [ethAddress, setEthAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  if (ethAddressIn && ethAddressIn != ethAddress) {
    setEthAddress(ethAddressIn);
  }

  useEffect(() => {
    setEthAddress(ethAddressIn);
  }, [ethAddressIn]);


  const handleLogin = async () => {
    if (!window.ethereum) {
      alert('Please install a Web3 wallet to be able to cook.');
      return
    }
    setIsLoading(true);
    try {
      // Request account access if needed
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const web3 = new Web3(window.ethereum);

      // Get the current user's Ethereum address
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];

      // Request nonce from backend for the Ethereum address
      const response = await axios.post(`${REACT_APP_BACKEND}/auth/request-nonce`, { ethAddress: account });
      const nonce = response.data.nonce;

      // Sign the nonce with the user's Ethereum account
      const signature = await web3.eth.personal.sign(`I am signing my one-time nonce for Master Cooker${titleText == 'MC' ? '' : ' API'}: ${nonce}`, account, '');

      // Send the Ethereum address and signature to the backend for verification
      const loginResponse = await axios.post(`${REACT_APP_BACKEND}/auth/verify-signature${titleText == 'MC' ? '' : '-api'}`, {
        ethAddress: account,
        signature,
      });

      if (loginResponse?.data?.accessToken) {
        setEthAddress(account);
        onLogin(loginResponse.data);
      }

      // Handle login logic with the response
      // e.g., save tokens, redirect, show errors, etc.
      //console.log(loginResponse.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error during login:', error);
      alert('An error occurred during login.');
    }
  };

  let url;
  if (titleText === 'MC API') {
    url = '/developer';
  } else {
    url = '/';
  }

  return (

    <nav data-v-b3d01395="" data-v-aaa30f88="" className="">
    <a data-v-b3d01395="" href={url} rel="noopener noreferrer" style={{fontSize:20, marginLeft:24, marginTop:2}}>
      {titleText}
    </a>
    <button data-v-b857464c="" data-v-b3d01395="" className="button menu-toggle icon style-RgKIf" id="style-RgKIf">
      <svg data-v-b3d01395="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <title>
          menu
        </title>
        <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z">
        </path>
      </svg>
    </button>
    <div data-v-b3d01395="" className="menu">
      {ethAddress &&
        <BuyCredits contractAddress={contractAddress} abi={MasterCookerCreditsABI} ethAddress={ethAddress} updateCreditBalance={updateCreditBalance}/>
      }
      <button data-v-0b6ae872="" data-v-10cea003="" className={(ethAddress == '') ? 'button' : 'button nonClickableButton'}  onClick={handleLogin} disabled={isLoading || (ethAddress != '')} >
        <span data-v-56eb38e8="" data-v-10cea003="" className="small upper connected">
        <span data-v-56eb38e8="">{ethAddress ? ethAddress.substring(0,8) : 'Log In'} </span>
        </span>
      </button>
      { ethAddress && 
      <CreditBalance updateCreditBalance={updateCreditBalance} creditBalance={creditBalance} />
      }
            {ethAddress && <Logout onLogout={onLogout}/>
      }
    </div>
  </nav>
  );
};

export default NavBar;