import React from 'react';
import "./Logout.css"

const Logout = ({ onLogout }) => {

  return (
    <><button data-v-0b6ae872="" data-v-10cea003="" className="button" onClick={onLogout} disabled=
    {false}>
      Logout
  </button>
      </>
  );
};

export default Logout;
