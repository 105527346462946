import React from 'react';

const CreditBalance = ({ updateCreditBalance, creditBalance }) => {

  updateCreditBalance();

  return (
    <>
      <button data-v-0b6ae872="" data-v-10cea003="" className='button nonClickableButton'>Avail. Cooks: {creditBalance}</button>
    </>
  );
};

export default CreditBalance;
