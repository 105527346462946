import React, { useState } from 'react';

function CopyToClipboardButton({ textToCopy }) {
  const [copied, setCopied] = useState(false);

  const copyTextToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Message disappears after 2 seconds
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  return (
    <div>
      <button style={{height:46, background:'white', color:'black' }} data-v-0b6ae872="" data-v-10cea003="" className="button" id="copyButton" onClick={copyTextToClipboard}>Get API Token</button>
      {copied && <p style={{fontSize:12, textAlign:'center'}}>Copied to Clipboard</p>}
    </div>
  );
}

export default CopyToClipboardButton;
