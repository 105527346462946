import React from 'react';
import ChecksComponent from '../ChecksComponent/ChecksComponent';
import NavBar from '../NavBar/NavBar';

const Default = ({ethAddress, handleLogin, handleLogout, creditBalance, updateCreditBalance, accessToken, loginAction, contractAddress}) => {
  return (
    <div className='body'>
        <NavBar  titleText={"MC"}ethAddressIn={ethAddress} onLogin={handleLogin} onLogout={handleLogout} creditBalance={creditBalance} updateCreditBalance={updateCreditBalance} contractAddress={contractAddress}/>
        <ChecksComponent ethAddress={ethAddress} accessToken={accessToken} updateCreditBalance={updateCreditBalance} loginAction={loginAction}/>
        <div style={{position:'fixed', bottom: 0, right: 0, padding:10}}>
          <a href="/developer">Master Cooker API</a>
        </div>
        <div style={{position:'fixed', bottom: 0, left: 0, padding:10}}>
          <a href="https://x.com/cookingchecks" target="_blank">@cookingchecks</a>
        </div>
    </div>
  );
}

export default Default;
