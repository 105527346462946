import React from 'react';
import NavBar from '../NavBar/NavBar';
import CopyToClipboardButton from '../CopyToClipboardButton/CopyToClipboardButton';

const Developer = ({ accessToken, ethAddress, onLogin, onLogout, creditBalance, updateCreditBalance, contractAddress }) => {

    const handleDocClick = () => {
        window.open('https://documenter.getpostman.com/view/6987996/2s9YkoehBB', '_blank');
    }

  return (
    <div className='body'>
      <NavBar titleText={"MC API"} ethAddressIn={ethAddress} onLogin={onLogin} onLogout={onLogout} creditBalance={creditBalance} updateCreditBalance={updateCreditBalance} contractAddress={contractAddress}/>
      <div style={{display:'flex', alignItems: 'flex-start', justifyContent:'center'}}>
        { accessToken &&
        <CopyToClipboardButton textToCopy={accessToken} />
        }
        <div>
        <button style={{height:46, background:'white', color:'black', marginLeft:15 }} data-v-0b6ae872="" data-v-10cea003="" className="button" id="copyButton" onClick={handleDocClick}>View API Docs</button>
        <div style={{ position: 'fixed', bottom: 0, right: 0, paddingBottom: 10, paddingRight: 15 }}>
            <a href="/">Master Cooker UI</a>
        </div>
        <div style={{position:'fixed', bottom: 0, left: 0, padding:10}}>
            <a href="https://x.com/cookingchecks" target="_blank">@cookingchecks</a>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Developer;
